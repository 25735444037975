import React from "react";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="app-container">
      <div className="content">
        <div className="logo-container">
          <div className="logo">
            <h1>Magic</h1>
            <div className="logo-icon"></div>
            <h1>Click</h1>
          </div>
        </div>
        <div className="tagline">
          <h2>The new frontier of campaign automation</h2>
          <div className="coming-soon">
            <span>Coming Soon</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
